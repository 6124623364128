import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CalloutComponent } from './callout/callout.component';
import { LogoutComponent } from './logout/logout.component';
import { ChangePasswordComponent } from './change-password/change-password.component'
import { MFAComponent } from './mfa/mfa.component'
import {ReauthenticateFormComponent} from "./reauthenticate/reauthenticate-form.component";
import {ApplicationsComponent} from "./applications/applications.component";
import { ResetMfaComponent } from "./resetmfa/resetmfa.component";
import { TenantsComponent } from "./Tenants/tenants.component";
const routes: Routes = [
  {
    path: 'login',
    component: LoginFormComponent
  },
  {
    path: 'reauthenticate',
    component: ReauthenticateFormComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent
  },
  {
    path: 'callout',
    component: CalloutComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'setupmfa',
    component: MFAComponent
  },
  {
    path: 'applications',
    component: ApplicationsComponent
  }, 
  {
    path: 'resetmfa',
    component: ResetMfaComponent
  }, 
  {
    path: 'tenants',
    component: TenantsComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
