import {Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {DataService} from "../data.service";
import {ConfigService} from "../config.service";
import {LocalizationService} from "../localization.service";
import {CommonService} from "../common.service";
import {MatPaginator} from "@angular/material/paginator";
import {UntypedFormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";

interface TableData {
    bw_customer_id: string
    tenant_code: string
    customer_codes: []
    url: string
    scope:string
}

@Component({
    selector: 'tenants',
    templateUrl: './tenants.component.html',
    styleUrls: ['./tenants.component.scss']
})


export class TenantsComponent implements OnInit {

    constructor(
        private config: ConfigService,
        private data: DataService,
        private localization: LocalizationService,
        public common: CommonService
    ) {
        this.searchControl = new UntypedFormControl();
        this.searchControl.valueChanges.pipe(debounceTime(200)).subscribe(searchValue => {
            console.log(searchValue)
            this.searchFilter(searchValue)
        });
    }

    @HostBinding('style.max-width') maxWidth = '960px';
    dataSource = new MatTableDataSource <TableData> ();
    columnsToDisplay = ['tenantCode', 'tableID' , 'customerName', 'actions'];
    @Input() tenantsList

    @Output() opsTenantsErrorEvent = new EventEmitter();
    noTenantAccessErrorMessage1 = '';

    length = 100;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    maxWidthStyle="960px";

    searchControl: UntypedFormControl;
  
    userTenants:TableData[] = [];

    @ViewChild('paginator1', {
        static: false
    }) paginator1: MatPaginator;


    ngOnInit() {
        this.data.sendData(true);
        this.maxWidthStyle = "960px";
        this.dataSource.data = [];
        this.userTenants = [];
        this.userTenants = this.data.getTenants();
        this.dataSource.data = this.data.getTenants();
        setTimeout(() => {this.dataSource.paginator = this.paginator1;});
    }

    ngOnChanges(changes: SimpleChanges) {        
        // if( changes['tenantsList'] && changes['tenantsList'].previousValue != changes['tenantsList'].currentValue ) {
        //     console.log(changes['tenantsList'].currentValue)
        //     this.dataSource.data = [];
        //     this.userTenants = [];
        //     this.userTenants.push(...changes['tenantsList'].currentValue)
        //     this.dataSource.data.push(...changes['tenantsList'].currentValue);
        //     setTimeout(() => {this.dataSource.paginator = this.paginator1;});
        // }        
    }

    searchFilter(searchValue: string) : void {
        if(searchValue && searchValue.toString().trim() !== "") {
            const userTenantsData: any = [];
            this.userTenants.forEach(element => {
                if (element.customer_codes.toString().toLowerCase().includes(searchValue.toString().trim().toLowerCase())
                || element.bw_customer_id.toString().toLowerCase().includes(searchValue.toString().trim().toLowerCase())
                || element.tenant_code.toString().toLowerCase().includes(searchValue.toString().trim().toLowerCase())) {
                    userTenantsData.push(element);
                }
            })
            this.dataSource.data = [];
            this.dataSource.data.push(...userTenantsData);
            this.dataSource.paginator = this.paginator1;
        }else {
            this.dataSource.data = [];
            this.dataSource.data.push(...this.userTenants);
            this.dataSource.paginator = this.paginator1;
        }
    }

    private getTenantCode(element) : string {
        if(element.tenant_code){
            return element.tenant_code
        }else if(element.code){
            return element.code
        }else{
            return "";
        }
    }

    private async validateAndRedirect(value: TableData) {
        console.log(value)

        const idToken = this.data.samlUserDetails != null ? this.data.samlUserDetails["id_token"] : localStorage.getItem('idToken') != null ?  localStorage.getItem('idToken') : '';
        let allTenants = localStorage.getItem('allTenants')
        let result = await this.data.validateTokenAndCreateTenantToken(value.tenant_code, value.bw_customer_id, idToken, allTenants,value.scope);

        if (result == false) {
            this.maxWidth = '480px';
            this.opsTenantsErrorEvent.emit({"tenant_code":value.tenant_code});
       
        } else if (result == true && value.url != "") {
            localStorage.setItem("tenantSelected", "true")
            location.href = decodeURIComponent(value.url);
        }
    }
    
    private formatLongAndShortName(value : string) : string{
        return value.toString().replace(/,/g, ', ');
    }

    tr(text: string) {
        return this.localization.translate(text);
    }

}
