import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {
    constructor(
        private router: Router,
        private data: DataService,
        private common: CommonService,
        private config: ConfigService,
        private localization: LocalizationService
    ) { 
        common.title = this.tr('access.logout.title');
        common.clearError();
    }

    opsParameter = null;
    loggedout = false;
    accessRedirect() {
        try {
            var origin = this.config.getParameterByName('origin');
            let application = this.config.getParameterByName('application');            
            if (origin && this.config.isAllowedToRedirect(origin)) {
                
                if (this.opsParameter) {
                    const separator = origin && origin.includes('?') ? '&' : '?';
                    origin = origin + separator + 'ops=' + encodeURIComponent(this.opsParameter);
                }              
                window.location.href = origin;
            }
            else if(application && application == 'cloudscan') {
                this.router.navigateByUrl('/applications?application='+application);
            }
            else {
                if (this.opsParameter)
                    this.router.navigate(['/login'], { queryParams: { ops: this.opsParameter } });
                else                    
                    this.router.navigate(['/login']);
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }
    
    async ngOnInit() {
        try {
            await this.data.deleteSession();
            var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
            this.opsParameter = amplifyInfo ? amplifyInfo.opsParameter : null;
            localStorage.removeItem('amplifyInfo');
            localStorage.removeItem('allTenants');
            localStorage.removeItem('idToken');
            localStorage.removeItem('tenantsList');
            this.config.delete_cookie("mfa");
            var me = this;
            setTimeout(function(){ me.isLoggedOut() }, 1000);
        }
        catch (err) {
            var me = this;
            setTimeout(function(){ me.isLoggedOut() }, 1000);
        }
    }

    isLoggedOut() {
        this.loggedout = true;
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}